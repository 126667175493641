<template>
  <v-container class="my-6 mx-auto" style="max-width: 1500px">
    <v-row>
      <v-col cols="12">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">Xичээлүүд</h5>
        <p class="text-body">Сурагчийн үзэx xичээлүүд</p>
      </v-col>
    </v-row>
    <section v-if="lessons && lessons.length > 0">
      <v-simple-table class="table border-radius-xl">
        <template v-slot:default>
          <thead>
            <tr>
              <th>ID</th>
              <th
                v-for="(hname, hindex) in headerNames"
                :key="'header' + hindex"
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                {{ hname.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in lessons" :key="item.id">
              <td class="text-sm text-secondary" style="width: 1%">
                {{ i + 1 }}
              </td>
              <td
                class="mb-0"
                :class="{
                  'border-bottom': i != lessons.length - 1,
                }"
              >
                {{ item.courseInfo.COURSE_NAME }}
              </td>
              <td>
                <small
                  style="cursor: pointer; height: 25px"
                  @click="_detail(item)"
                  small
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 bg-gradient-warning"
                  elevation="0"
                  :ripple="false"
                  >xараx</small
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </section>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  data: () => ({
    lessons: null,
    headerNames: [
      {
        text: "Нэр",
        align: "end",
        value: "index",
        sortable: true,
      },
      {
        text: "Үйлдэл",
        align: "end",
        value: "index",
        sortable: true,
      },
    ],
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  async created() {
    await this._setupp();
    setTimeout(() => {
      this.lessons = this.lessons.sort(
        (a, b) =>
          b.courseInfo.ENROLLMENT_CATEGORY.length -
          a.courseInfo.ENROLLMENT_CATEGORY.length
      );
    }, 1500);
  },

  methods: {
    _detail(item) {
      console.log(item.ref.path);
      this.$router.push({
        name: "StudentLessonDetail",
        params: {
          path: item.ref.path,
        },
      });
    },
    async _setupp() {
      await this.userData.school.ref
        .collection("lessons-" + this.userData.school.currentYear)
        .where("programId", "==", String(this.userData.STUDENT_GROUP_ID))
        .orderBy("courseInfo.COURSE_NAME", "asc")
        .onSnapshot(async (docs) => {
          this.lessons = [];
          await docs.forEach(async (doc) => {
            let lesson = doc.data();
            lesson.ref = doc.ref;
            lesson.id = doc.id;
            if (
              lesson.esisLessonType &&
              lesson.esisLessonType.esisLessonTypeId > 1
            ) {
              lesson.inLessonGroups = false;
              lesson.lessonGroups = null;
              await lesson.ref
                .collection("lesson-groups")
                .get()
                .then(async (docs) => {
                  lesson.lessonGroups = [];
                  await docs.forEach(async (doc) => {
                    let gg = doc.data();
                    gg.id = doc.id;
                    gg.ref = doc.ref;
                    await gg.ref
                      .collection("students")
                      .doc(String(this.userData.PERSON_ID))
                      .get()
                      .then((doc) => {
                        if (doc.exists) {
                          lesson.inLessonGroups = true;
                          // console.log(lesson.inLessonGroups);
                        }
                      });
                    lesson.lessonGroups.push(gg);
                  });
                  setTimeout(() => {
                    if (lesson.inLessonGroups) {
                      this.lessons.push(lesson);
                    }
                  }, 500);
                });
            } else {
              this.lessons.push(lesson);
            }
          });
        });
    },
  },
};
</script>
